exports.components = {
  "component---src-views-404-jsx": () => import("./../../../src/views/404.jsx" /* webpackChunkName: "component---src-views-404-jsx" */),
  "component---src-views-about-us-jsx": () => import("./../../../src/views/about-us.jsx" /* webpackChunkName: "component---src-views-about-us-jsx" */),
  "component---src-views-alternatives-aspera-jsx": () => import("./../../../src/views/alternatives/aspera.jsx" /* webpackChunkName: "component---src-views-alternatives-aspera-jsx" */),
  "component---src-views-alternatives-filecatalyst-jsx": () => import("./../../../src/views/alternatives/filecatalyst.jsx" /* webpackChunkName: "component---src-views-alternatives-filecatalyst-jsx" */),
  "component---src-views-alternatives-masv-jsx": () => import("./../../../src/views/alternatives/masv.jsx" /* webpackChunkName: "component---src-views-alternatives-masv-jsx" */),
  "component---src-views-alternatives-media-shuttle-jsx": () => import("./../../../src/views/alternatives/media-shuttle.jsx" /* webpackChunkName: "component---src-views-alternatives-media-shuttle-jsx" */),
  "component---src-views-alternatives-resilio-sync-jsx": () => import("./../../../src/views/alternatives/resilio-sync.jsx" /* webpackChunkName: "component---src-views-alternatives-resilio-sync-jsx" */),
  "component---src-views-apply-apply-for-event-jsx": () => import("./../../../src/views/apply/applyForEvent.jsx" /* webpackChunkName: "component---src-views-apply-apply-for-event-jsx" */),
  "component---src-views-apply-apply-nab-jsx": () => import("./../../../src/views/apply/applyNAB.jsx" /* webpackChunkName: "component---src-views-apply-apply-nab-jsx" */),
  "component---src-views-apply-apply-success-jsx": () => import("./../../../src/views/apply/apply-success.jsx" /* webpackChunkName: "component---src-views-apply-apply-success-jsx" */),
  "component---src-views-apply-applysem-jsx": () => import("./../../../src/views/apply/applysem.jsx" /* webpackChunkName: "component---src-views-apply-applysem-jsx" */),
  "component---src-views-calculator-jsx": () => import("./../../../src/views/calculator.jsx" /* webpackChunkName: "component---src-views-calculator-jsx" */),
  "component---src-views-examples-detail-jsx": () => import("./../../../src/views/examples/detail.jsx" /* webpackChunkName: "component---src-views-examples-detail-jsx" */),
  "component---src-views-examples-index-jsx": () => import("./../../../src/views/examples/index.jsx" /* webpackChunkName: "component---src-views-examples-index-jsx" */),
  "component---src-views-home-index-jsx": () => import("./../../../src/views/home/index.jsx" /* webpackChunkName: "component---src-views-home-index-jsx" */),
  "component---src-views-license-purchase-smb-jsx": () => import("./../../../src/views/license/purchase-smb.jsx" /* webpackChunkName: "component---src-views-license-purchase-smb-jsx" */),
  "component---src-views-license-purchase-success-jsx": () => import("./../../../src/views/license/purchase-success.jsx" /* webpackChunkName: "component---src-views-license-purchase-success-jsx" */),
  "component---src-views-partners-partners-jsx": () => import("./../../../src/views/partners/partners.jsx" /* webpackChunkName: "component---src-views-partners-partners-jsx" */),
  "component---src-views-pricing-pricing-jsx": () => import("./../../../src/views/pricing/pricing.jsx" /* webpackChunkName: "component---src-views-pricing-pricing-jsx" */),
  "component---src-views-privacy-policy-jsx": () => import("./../../../src/views/privacy-policy.jsx" /* webpackChunkName: "component---src-views-privacy-policy-jsx" */),
  "component---src-views-products-cloud-cloud-jsx": () => import("./../../../src/views/products/cloud/cloud.jsx" /* webpackChunkName: "component---src-views-products-cloud-cloud-jsx" */),
  "component---src-views-products-enterprise-jsx": () => import("./../../../src/views/products/enterprise.jsx" /* webpackChunkName: "component---src-views-products-enterprise-jsx" */),
  "component---src-views-products-smb-jsx": () => import("./../../../src/views/products/smb.jsx" /* webpackChunkName: "component---src-views-products-smb-jsx" */),
  "component---src-views-solutions-dashboard-index-jsx": () => import("./../../../src/views/solutions/dashboard/index.jsx" /* webpackChunkName: "component---src-views-solutions-dashboard-index-jsx" */),
  "component---src-views-solutions-files-index-jsx": () => import("./../../../src/views/solutions/files/index.jsx" /* webpackChunkName: "component---src-views-solutions-files-index-jsx" */),
  "component---src-views-solutions-industry-car-jsx": () => import("./../../../src/views/solutions/industry/car.jsx" /* webpackChunkName: "component---src-views-solutions-industry-car-jsx" */),
  "component---src-views-solutions-industry-financing-jsx": () => import("./../../../src/views/solutions/industry/financing.jsx" /* webpackChunkName: "component---src-views-solutions-industry-financing-jsx" */),
  "component---src-views-solutions-industry-gaming-jsx": () => import("./../../../src/views/solutions/industry/gaming.jsx" /* webpackChunkName: "component---src-views-solutions-industry-gaming-jsx" */),
  "component---src-views-solutions-industry-gene-jsx": () => import("./../../../src/views/solutions/industry/gene.jsx" /* webpackChunkName: "component---src-views-solutions-industry-gene-jsx" */),
  "component---src-views-solutions-industry-manufacturing-jsx": () => import("./../../../src/views/solutions/industry/manufacturing.jsx" /* webpackChunkName: "component---src-views-solutions-industry-manufacturing-jsx" */),
  "component---src-views-solutions-industry-marine-jsx": () => import("./../../../src/views/solutions/industry/marine.jsx" /* webpackChunkName: "component---src-views-solutions-industry-marine-jsx" */),
  "component---src-views-solutions-industry-media-jsx": () => import("./../../../src/views/solutions/industry/media.jsx" /* webpackChunkName: "component---src-views-solutions-industry-media-jsx" */),
  "component---src-views-solutions-industry-tech-jsx": () => import("./../../../src/views/solutions/industry/tech.jsx" /* webpackChunkName: "component---src-views-solutions-industry-tech-jsx" */),
  "component---src-views-solutions-large-file-index-jsx": () => import("./../../../src/views/solutions/large-file/index.jsx" /* webpackChunkName: "component---src-views-solutions-large-file-index-jsx" */),
  "component---src-views-solutions-p-2-p-index-jsx": () => import("./../../../src/views/solutions/p2p/index.jsx" /* webpackChunkName: "component---src-views-solutions-p-2-p-index-jsx" */),
  "component---src-views-solutions-sdk-index-jsx": () => import("./../../../src/views/solutions/sdk/index.jsx" /* webpackChunkName: "component---src-views-solutions-sdk-index-jsx" */),
  "component---src-views-solutions-share-index-jsx": () => import("./../../../src/views/solutions/share/index.jsx" /* webpackChunkName: "component---src-views-solutions-share-index-jsx" */),
  "component---src-views-solutions-sync-index-jsx": () => import("./../../../src/views/solutions/sync/index.jsx" /* webpackChunkName: "component---src-views-solutions-sync-index-jsx" */),
  "component---src-views-term-of-service-jsx": () => import("./../../../src/views/term-of-service.jsx" /* webpackChunkName: "component---src-views-term-of-service-jsx" */)
}

